import '../css/petal.scss';

function Petal(props) {
    let petalClass = `petal-fly-${props.isUp ? 'up' : 'down'}-${props.isRight ? 'right' : 'left'}`
    return <div className={petalClass} style={{width: props.width, height: props.height}}>
        <div className="petal-fly">
            <div className="petal" style={{animationDelay: (Math.random() + 0.5) + 's'}}>{props.children}</div>
        </div>
    </div>
}

export default Petal;