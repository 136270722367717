import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import {Helmet} from "react-helmet";
import favicon from './images/favicon.ico'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Helmet>
            <title>For PQN</title>
            <link rel="icon" href={favicon}/>
        </Helmet>
        <App/>
    </React.StrictMode>
);
