import Petal from "./Petal";
import '../css/canvas.scss';
import Paths from "./Paths";
import Square from "./Square";
import {useState} from "react";

function drawPetal(petal, key, path, color) {
    return <Petal key={path.name + '_' + key}
                  isUp={petal.isUp}
                  isRight={petal.isRight}
                  width={petal.width + '%'}
                  height={petal.height + '%'}>
        <svg width="1em" height="1em" viewBox={`0 0 ${path.viewPort} ${path.viewPort}`}>
            <path fill={color} d={path.path}/>
        </svg>
    </Petal>;
}

function Canvas() {
    const animationTypes = ['none', 'beating', 'shaking'],
        backgroundColors = [
            {
                code: '#171614',
                name: 'Eerie Black'
            },
            {
                code: '#273E47',
                name: 'Charcoal'
            },
            {
                code: '#0D2818',
                name: 'Phthalo Green'
            },
            {
                code: '#33261D',
                name: 'Bistre'
            },
            {
                code: '#672330',
                name: 'Wine'
            }
        ],
        colors = [
            {
                code: '#d7174b',
                name: 'Crimson'
            },
            {
                code: '#17d771',
                name: 'Malachite'
            },
            {
                code: '#77e7e3',
                name: 'Crayola'
            },
        ],
        [color, setColor] = useState(0),
        [background, setBackground] = useState(0),
        [animationType, setAnimationType] = useState('beating'),
        [path, setPath] = useState(Paths.heart),
        fragments = 240,
        pathLength = path.getTotalLength(),
        changePath = function (e) {
            return setPath(Paths[e.target.value]);
        };
    let petals = [];
    for (let l = 0; l < pathLength; l += pathLength / fragments) {
        let targetPoint = path.getPointAtLength(l);

        petals.push({
            isUp: targetPoint.y < path.viewPort / 2,
            isRight: targetPoint.x > path.viewPort / 2,
            width: Math.abs(targetPoint.x - path.viewPort / 2) / path.viewPort * 100,
            height: Math.abs(targetPoint.y - path.viewPort / 2) / path.viewPort * 100,
        })
    }

    return <div className="canvas-wrapper" style={{background: backgroundColors[background].code}}>
        <div className={'canvas ' + animationType}>
            <Square>
                {petals.map((petal, key) => drawPetal(petal, key, path, colors[color].code))}
            </Square>
        </div>
        <div className="controller">
            <div className="control">
                <div className="label">
                    Path
                </div>
                <div className="option">
                    <select onChange={changePath} value={path.code}>
                        {Object.keys(Paths).map((name, key) => <option key={key} value={name}>{Paths[name].name}</option>)}
                    </select>
                </div>
            </div>
            <div className="control">
                <div className="label">
                    Animation Type
                </div>
                <div className="option">
                    <select onChange={e => setAnimationType(e.target.value)} value={animationType}>
                        {animationTypes.map((type, key) => <option key={key} value={type}>{type.replace(/\b\w/g, l => l.toUpperCase())}</option>)}
                    </select>
                </div>
            </div>
            <div className="control">
                <div className="label">
                    Particle Color
                </div>
                <div className="option">
                    <select onChange={e => setColor(e.target.value)}> value={color}
                        {colors.map((color, key) => <option key={key} value={key}>{color.name}</option>)}
                    </select>
                </div>
            </div>
            <div className="control">
                <div className="label">
                    Background Color
                </div>
                <div className="option">
                    <select onChange={e => setBackground(e.target.value)} value={background}>
                        {backgroundColors.map((color, key) => <option key={key} value={key}>{color.name}</option>)}
                    </select>
                </div>
            </div>
        </div>
    </div>
}

export default Canvas;