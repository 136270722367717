import {useState, useEffect, useRef} from 'react';


function Square(props) {
    const ref = useRef(null);
    const [width, setWidth] = useState(0);

    useEffect(function () {
        function handleResize() {
            setWidth(ref.current.offsetWidth);
        }

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    return <div className={props.className || ''} ref={ref} style={{height: width}}>{props.children}</div>
}

export default Square;