import Canvas from "./Canvas";
import '../css/app.scss';

function App() {
    return (
        <div className="App">
            <Canvas/>
        </div>
    );
}

export default App;
